import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout' 
import ReactMarkdown from "react-markdown"
import {Carousel} from 'react-bootstrap'
import SEO from "../components/seo"
import right from "./../images/right.svg"
import left from "./../images/left.svg"

const BehandelingTemplate = ({ data, pageContext }) => (
  <Layout>
    <SEO title={data.strapiBehandeling.meta_titel} description={data.strapiBehandeling.meta_omschrijving} lang="nl" />
    <div className="container">
      <div className="row no-gutters cta-wiezijnwe mb-5 ">
        <div className="col-lg-6 d-flex flex-column justify-content-center bg-wervel">
          <div className="cta-wiezijnwe-tekst">
            <h1 className="specialisatie-titel">{data.strapiBehandeling.titel}</h1>
            <ReactMarkdown source={data.strapiBehandeling.tekst} />
          </div> 
        </div>
        <div className="col-lg-6 cta-wiezijnwe-afb">
            <Carousel>
              {data.strapiBehandeling.afbeeldingen.sort((a, b) => (a.name > b.name) ? 1 : -1).map(document =>(
                <Carousel.Item key={document.id}>
                  {document.width > document.height &&
                    <img
                      className="d-block w-100"
                      src={document.url}
                      alt={document.alternativeText}
                    />
                  }
                  {document.width < document.height &&
                    <img
                      className="d-block carouselItemPortrait"
                      src={document.url}
                      alt={document.alternativeText}
                    />
                  }
                </Carousel.Item>
              ))}
            </Carousel>
        </div>
      </div>
      <>
        {data.strapiBehandeling.tekst2 &&
          <div className="row">
            <div className="col-lg-9 mb-5 team-opleidingen">
              <ReactMarkdown source={data.strapiBehandeling.tekst2} />
            </div>
          </div>
        }
      </>
      <div className="row mb-5">
        <div className="col-md-6 mb-2 mb-md-0">
          {pageContext.prev && pageContext.prev.titel && pageContext.prev.titel !== "" &&
            <Link to={`/specialisaties/${pageContext.prev.titel.replace(/\s/g, "").toLowerCase()}`}>
              <span className="btn btn-primary"><img src={left} height="24" alt="vorige specialisatie" /> {pageContext.prev.titel}</span>
            </Link>
          }
        </div>
        <div className="col-md-6 text-right">
          {pageContext.next && pageContext.next.titel && pageContext.next.titel !== "" &&
            <Link to={`/specialisaties/${pageContext.next.titel.replace(/\s/g, "").toLowerCase()}`}>
              <span className="btn btn-primary">{pageContext.next.titel} <img src={right} height="24" alt="volgende specialisatie" /></span>
            </Link>
          }
        </div>
      </div>
    </div>
  </Layout>
)

export default BehandelingTemplate

export const query = graphql`
  query BehandelingTemplate($id: String!) {
    strapiBehandeling(id: {eq: $id}) {
      id
      titel
      tekst
      tekst2
      meta_titel
      meta_omschrijving
      afbeeldingen {
        id
        alternativeText
        width
        height
        name
        url
      }
    }
  }
`
